<template>
  <div class="question-page page-main">
    <a-spin :spinning="loading">
      <div
        class="container"
        :style="{
          'justify-content': practice?.length > 0 ? 'unset' : 'center',
        }"
      >
        <template v-if="!loading && practice && practice.length > 0">
          <div
            class="box box-shadow-1"
            v-for="item in practice"
            :key="item.practice_id"
          >
            <div class="name">{{ item.subject_name }}</div>
            <div class="school">
              {{ item.school_name }}<text class="line">|</text
              >{{ item.class_name }}
            </div>
            <div class="time">
              {{ item.practice_start_time }}<text class="line">-</text
              >{{ item.practice_end_time }}
            </div>
            <div style="text-align: right">
              <div class="start-btn" @click="startPractice(item)">开始练习</div>
            </div>
          </div>
        </template>
        <a-empty
          v-if="!loading && practice?.length == 0"
          description="暂无题库"
        ></a-empty>
      </div>
    </a-spin>
  </div>
</template>

<script>
import { computed, defineComponent, reactive, toRefs } from "@vue/runtime-core";
import url from "../../utils/url";
import { getPracticeList } from "@/api/main.js";
import { message } from "ant-design-vue";
// import { useStore } from "vuex";
import { getUserId } from "@/utils/storeData.js";

export default defineComponent({
  name: "QuestionIndex",
  setup() {
    // const store = useStore();
    const pageData = reactive({
      practice: [],
      loading: false,
      user_id: computed(() => getUserId()),
    });
    const startPractice = (item) => {
      url.navigateTo("/question/question", {
        practiceId: item.practice_id,
        practiceName: item.subject_name,
      });
    };
    const getPracticeListFun = () => {
      pageData.loading = true;
      getPracticeList({ user_id: pageData.user_id })
        .then((res) => {
          if (res.code == 200) {
            pageData.practice = res.data.practice;
          } else {
            message.error(res.msg);
          }
        })
        .catch((res) => {
          console.log(res);
        })
        .finally(() => {
          pageData.loading = false;
        });
    };
    getPracticeListFun();

    return { ...toRefs(pageData), startPractice };
  },
});
</script>

<style lang="less" scoped>
.question-page {
  height: 100%;
}
.container {
  padding: 30px 0 60px;
  height: unset;
  display: flex;
  align-items: unset;
  // justify-content: center;
  flex-wrap: wrap;
  .box {
    width: 384px;
    margin-right: 24px;
    background-color: #ffffff;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
    &:nth-child(3n) {
      margin-right: 0;
    }
    .name {
      color: #333333;
      font-size: 18px;
      margin-bottom: 24px;
    }

    .school {
      color: #666666;
      margin-bottom: 12px;
    }

    .time {
      color: #969696;
      font-size: 14px;
      margin-bottom: 15px;
    }

    .line {
      display: inline-block;
      margin: 0 10px;
    }

    .start-btn {
      padding: 0 12px;
      height: 30px;
      line-height: 30px;
      background-color: #1e83e5;
      border-radius: 24px;
      text-align: center;
      color: #ffffff;
      font-size: 13px;
      cursor: pointer;
      display: inline-block;
      margin-right: 0;
      margin-left: auto;
    }
  }
}
</style>
